import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { FiPlus } from "react-icons/fi"

import JobCardMini from "./JobCardMini"
import { selectJobCards } from "../../../store/recruiter/selector"
import { selectSessionKey } from "../../../store/user/selector"
import { getListJobCards } from "../../../store/recruiter/action"

const JobCardBoard = ({ role }) => {
  const jobCards = useSelector(selectJobCards)
  const sessionKey = useSelector(selectSessionKey)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getListJobCards(sessionKey))
  }, [sessionKey, dispatch])

  useEffect(() => {
    if (jobCards?.length === 0) {
      history.push("/recruiter/create-job-card")
    }
  }, [jobCards?.length, history])

  return (
    <div className="job-card-board container light-style flex-grow-1 pt-5">
      <h4 className="font-weight-bold py-2 mb-3">JobCard Board</h4>
      <div className="bg-white shadow rounded-lg p-3 d-">
        <div className="cursor-pointer text-center">
          <Link to={`/recruiter/create-job-card`}>
            <FiPlus size={40} color="#4f16b6" title="Maak een JobCard" />
          </Link>
        </div>
        {jobCards?.map((card, index) => (
          <JobCardMini jobCard={card} key={index} role="recruiter" />
        ))}
      </div>
    </div>
  )
}

export default JobCardBoard
