import React from "react"
import { useDispatch } from "react-redux"
import { BsStarFill, BsStar } from "react-icons/bs"

import { setFavorite } from "../../store/recruiter/action"

const SetFavorite = (props) => {
  const dispatch = useDispatch()

  const { bookmarkedCandidate, protectedId, sessionKey } = props
  const { is_favorite } = bookmarkedCandidate

  //Set/remove favorite function
  const onClickSetFavorite = (
    sessionKey,
    protected_candidate_id,
    is_favorite,
    bookmarkedCandidate
  ) => {
    dispatch(
      setFavorite(
        sessionKey,
        protected_candidate_id,
        is_favorite,
        bookmarkedCandidate
      )
    )
  }

  return (
    <>
      {is_favorite ? (
        <div
          onClick={() =>
            onClickSetFavorite(
              sessionKey,
              protectedId,
              !is_favorite,
              bookmarkedCandidate
            )
          }
          className="cursor-pointer mx-2 "
        >
          <BsStarFill color="#4f16b6" size={20} title="Favoriet Verwijderen" />{" "}
        </div>
      ) : (
        <div
          onClick={() =>
            onClickSetFavorite(
              sessionKey,
              protectedId,
              !is_favorite,
              bookmarkedCandidate
            )
          }
          className="cursor-pointer mx-2 "
        >
          <BsStar color="#4f16b6" size={20} title="Favoriete" />
        </div>
      )}
    </>
  )
}

export default SetFavorite
