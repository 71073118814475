import React, { useState, useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import TextareaAutosize from "react-textarea-autosize"

import "./CreateJobCard.css"
import { selectSessionKey } from "../../../store/user/selector"
import { createJobCard } from "../../../store/recruiter/action"
import { selectRedirectUrl } from "../../../store/appState/selector"

import { apiPost } from "../../../utils/apiPostWrapper"
import { BsTrash } from "react-icons/bs"

const CreateJobCard = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const redirectToUrl = useSelector(selectRedirectUrl)
  const sessionKey = useSelector(selectSessionKey)
  const [cityOptions, setCityOptions] = useState([])
  const [isCityLoading, setIsCityLoading] = useState(false)
  const [profileOptions, setProfileOptions] = useState([])
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const [skilloptions, setSkillOptions] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [isSkillsLoading, setIsSkillsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    shouldUnregister: false,
  })

  const [jobTitle, description, location, profile] = watch([
    "jobTitle",
    "description",
    "location",
    "profile",
  ])

  // GET CITIES
  const loadCityOptions = async (searchTerm) => {
    try {
      setIsCityLoading(true)
      const citiesResponse = await apiPost(`/api/common/search/city`, {
        search_term: searchTerm,
      })
      setCityOptions(citiesResponse.data.cities)
      setIsCityLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsCityLoading(false)
    }
  }

  // GET PROFILE
  const loadProfileOptions = async (searchTerm) => {
    try {
      setIsProfileLoading(true)
      const profilesResponse = await apiPost(`/api/common/search/job_title`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setProfileOptions(profilesResponse.data.job_titles)
      setIsProfileLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsProfileLoading(false)
    }
  }

  //load skills options
  const loadSkillOptions = async (searchTerm) => {
    try {
      setIsSkillsLoading(true)
      const skillResponse = await apiPost(`/api/common/search/skill`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setSkillOptions(skillResponse.data.skills)
      setIsSkillsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsSkillsLoading(false)
    }
  }

  // handle profile change
  const handleProfileChange = async (selectedProfile) => {
    try {
      const suggested = await apiPost(
        `/api/recruiter/search/relevant_skills_for_job`,
        {
          session_key: sessionKey,
          job_title_id: selectedProfile.id,
        }
      )
      setSelectedSkills(suggested.data.skills.slice(0, 5))
    } catch (error) {
      console.log("error", error)
    }
  }

  const onSelectSkill = (selected) => {
    setSelectedSkills([...selectedSkills, selected[0]])
    setIsOpen(false)
    if (selected.length) {
      ref.current.clear()
    }
  }

  const removeSkill = (skillId) => {
    setSelectedSkills(selectedSkills.filter((skill) => skill.id !== skillId))
  }

  const handleCreateJobCard = () => {
    dispatch(
      createJobCard(
        sessionKey,
        jobTitle,
        location[0].id,
        description,
        profile.id,
        selectedSkills.map((skill) => skill.id)
      )
    )
  }

  useEffect(() => {
    if (redirectToUrl) {
      history.push(redirectToUrl)
    }
  }, [redirectToUrl, history])

  return (
    <div className="container  flex-grow-1 py-5">
      <h4 className="font-weight-bold py-2 mb-3">Een JobCard Aanmaken</h4>
      <div className="shadow p-4 bg-white">
        <form onSubmit={handleSubmit(handleCreateJobCard)}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">JobCard Title</label>
                <input
                  className="form-control"
                  name="jobTitle"
                  type="text"
                  id="jobTitle"
                  {...register("jobTitle", {
                    required: true,
                  })}
                />
                {errors.jobTitle?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Plaats</label>
                <Controller
                  control={control}
                  name="location"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AsyncTypeahead
                      {...field}
                      filterBy={() => true}
                      labelKey={(option) => `${option.name}`}
                      isLoading={isCityLoading}
                      minLength={2}
                      onSearch={loadCityOptions}
                      options={cityOptions}
                      bsSize="sm"
                      id="job-city"
                      placeholder="Kies een stad..."
                    />
                  )}
                />
                {errors.location?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label className="font-weight-bold">Functieomschrijving</label>
                <TextareaAutosize
                  className="form-control"
                  minRows={8}
                  {...register("description", {
                    required: true,
                  })}
                />

                {errors.description?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="font-weight-bold">Afgestudeerd Profiel</label>
                <Controller
                  control={control}
                  name="profile"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AsyncTypeahead
                      {...field}
                      filterBy={() => true}
                      labelKey={(option) => `${option.name}`}
                      id="graduate-profile"
                      isLoading={isProfileLoading}
                      minLength={3}
                      onSearch={loadProfileOptions}
                      options={profileOptions}
                      onChange={([selected]) => {
                        field.onChange(selected)
                        handleProfileChange(selected)
                      }}
                      bsSize="sm"
                      placeholder="Kies een profiel..."
                    />
                  )}
                />
                {errors.profile?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>
          {selectedSkills.length > 0 && (
            <div className="">
              <div className="form-group ">
                <label className="font-weight-bold"></label>

                <div
                  className="table-responsive"
                  style={{ overflowX: "inherit" }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="w-10"></th>

                        <th className="w-80">Vaardigheden</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedSkills.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className="">
                              <div
                                className="cursor-pointer"
                                onClick={() => removeSkill(item.id)}
                              >
                                {" "}
                                <BsTrash size={20} title="Rerwijderen" />
                              </div>{" "}
                            </td>
                            <td className="">
                              <div>{item.name}</div>{" "}
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td></td>
                        <td>
                          <AsyncTypeahead
                            ref={ref}
                            filterBy={() => true}
                            labelKey={(option) => `${option.name}`}
                            id="skill-dropdown"
                            isLoading={isSkillsLoading}
                            minLength={3}
                            onSearch={loadSkillOptions}
                            onChange={onSelectSkill}
                            options={skilloptions}
                            onBlur={() => setIsOpen(false)}
                            onFocus={() => setIsOpen(true)}
                            open={isOpen}
                            placeholder="Kies een vaardigheid..."
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <button className="button_primary" type="submit">
            JobCard Aanmaken
          </button>
        </form>
      </div>
    </div>
  )
}

export default CreateJobCard
