import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
// import { useHistory } from "react-router-dom"

import { apiPost } from "../../../utils/apiPostWrapper"
import { enrollAStudy } from "../../../store/student/action"

import { selectSessionKey } from "../../../store/user/selector"
import { selectRedirectUrl } from "../../../store/appState/selector"

export default function UserSettingPage({ role }) {
  const sessionKey = useSelector(selectSessionKey)
  const redirectToUrl = useSelector(selectRedirectUrl)
  const dispatch = useDispatch()
  const history = useHistory()
  const [schoolInputValue, setSchoolInputValue] = useState("")
  const [profileInputValue, setProfileInputValue] = useState("")

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      schoolName: "",
      firstYear: "",
      lastYear: "",
      profile: "",
    },
    shouldUnregister: false,
  })
  const { schoolName, firstYear, lastYear, profile } = watch()

  // load school options using API call
  const loadSchoolOptions = async () => {
    if (schoolInputValue.length > 2) {
      const response = await apiPost(`/api/common/search/school_name`, {
        search_term: schoolInputValue,
        country_code: "nl",
        lang_code: "nl",
      })
      return response.data.school_names
    }
  }

  // load profiles opitons using API call
  const loadProfileOptions = async () => {
    if (profileInputValue.length > 3) {
      const response = await apiPost(`/api/common/search/profile_revision`, {
        study_started_year: firstYear.value,
        search_term: profileInputValue,
        country_code: "nl",
        lang_code: "nl",
        school_id: schoolName.id,
      })

      return response.data.profiles
    }
  }

  const getProfileOptionLabel = (e) => {
    return (
      <>
        {" "}
        {e.name} - {e.education_name}{" "}
      </>
    )
  }

  const handleSchoolInputChange = (value) => {
    setSchoolInputValue(value)
  }
  const handleProfileInputChange = (value) => {
    setProfileInputValue(value)
  }
  //study years
  const currentYear = new Date().getFullYear()
  const firstYearOptions = Array.from(
    { length: currentYear - 2012 + 1 },
    (_, i) => 2012 + i
  ).map((year) => {
    return { value: year, label: year }
  })
  const lastYearOptions = Array.from(
    { length: 15 },
    (_, i) => firstYear.value + i + 2
  ).map((year) => {
    return { value: year, label: year }
  })

  // style of select bar
  const selectStyles = {
    control: (base) => ({ ...base, height: 40, minheight: 40 }),
    valueContainer: (base) => ({ ...base, height: 40, padding: "0 6px" }),
    input: (base) => ({ ...base, margin: "0px" }),
    indicatorSeparator: () => ({ display: "none" }),
  }

  //submit form
  const submitForm = async () => {
    dispatch(enrollAStudy(sessionKey, schoolName, firstYear, profile, lastYear))
    reset({ schoolName: "", firstYear: "", lastYear: "", profile: "" })
  }

  useEffect(() => {
    if (redirectToUrl) {
      history.push(redirectToUrl)
    }
  }, [redirectToUrl, history])

  return (
    <div className="container light-style flex-grow-1 pt-5">
      <h4 className="font-weight-bold py-2 mb-3">Een Studie Toevoegen</h4>

      <div className="bg-white shadow rounded-lg p-2">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="row__value mt-3">
                <Controller
                  control={control}
                  name="schoolName"
                  rules={{
                    required: "Deze invoer is vereist",
                  }}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      defaultOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      loadOptions={loadSchoolOptions}
                      onInputChange={handleSchoolInputChange}
                      isClearable={true}
                      styles={selectStyles}
                      placeholder="School"
                      loadingMessage={() => <> </>}
                      noOptionsMessage={() => <>Geen opties</>}
                    />
                  )}
                />

                <ErrorMessage
                  errors={errors}
                  name="schoolName"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="row__value mt-3">
                <Controller
                  name="firstYear"
                  control={control}
                  rules={{
                    required: "Deze invoer is vereist",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={firstYearOptions}
                      placeholder="Start jaar"
                      styles={selectStyles}
                    />
                  )}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name="firstYear"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type} className="error__message">
                      {message}
                    </p>
                  ))
                }
              />
            </div>

            <div className="col-sm-12">
              <div className="row__value mt-3">
                <Controller
                  name="profile"
                  control={control}
                  rules={{
                    required: "Deze invoer is vereist",
                  }}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      defaultOptions
                      getOptionLabel={getProfileOptionLabel}
                      getOptionValue={(e) => e.id}
                      loadOptions={loadProfileOptions}
                      onInputChange={handleProfileInputChange}
                      styles={selectStyles}
                      isClearable={true}
                      isDisabled={firstYear !== "" ? false : true}
                      placeholder="Studie"
                      noOptionsMessage={() => <>Geen opties</>}
                      loadingMessage={() => <> </>}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="profile"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row__value mt-3">
                <Controller
                  name="lastYear"
                  control={control}
                  rules={{
                    required: "Deze invoer is vereist",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={lastYearOptions}
                      placeholder="Laatste jaar"
                      styles={selectStyles}
                      value={lastYear}
                      isDisabled={firstYear !== "" ? false : true}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastYear"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </div>
          </div>
          <button className="button_primary mb-2" type="submit">
            Studie Toevoegen
          </button>
        </form>
      </div>
    </div>
  )
}
