import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { BsSearch, BsTrash } from "react-icons/bs"

import "./Bookmarks.css"
import Table from "../../../components/Table/Table"
import SetFavorite from "../../../components/SetFavorite/SetFavorite"

import {
  getListBookmarks,
  deleteBookmarkACandi,
} from "../../../store/recruiter/action"
import { selectBookmarkCandidates } from "../../../store/recruiter/selector"
import { selectSessionKey } from "../../../store/user/selector"

const Bookmarks = () => {
  const [filterInput, setFilterInput] = useState("")
  const dispatch = useDispatch()
  const listBookmarks = useSelector(selectBookmarkCandidates)
  const sessionKey = useSelector(selectSessionKey)
  const sortedBookmarks = listBookmarks?.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  )

  const filteredBookmarks = sortedBookmarks.filter((b) => {
    const firstNameMatch = b.first_name
      .toLowerCase()
      .includes(filterInput.toLocaleLowerCase())
    const lastNameMatch = b.last_name
      .toLowerCase()
      .includes(filterInput.toLocaleLowerCase())
    const nameMatch = (
      b.first_name.toLowerCase() +
      " " +
      b.last_name.toLowerCase()
    ).includes(filterInput.toLocaleLowerCase())
    const locationMatch = b.work_city_name
      .toLowerCase()
      .includes(filterInput.toLocaleLowerCase())

    return firstNameMatch || lastNameMatch || nameMatch || locationMatch
  })

  //Delete Bookmark function
  const deleteBookmark = (sessionKey, bookmarkedCandidate) => {
    dispatch(deleteBookmarkACandi(sessionKey, bookmarkedCandidate))
  }

  useEffect(() => {
    if (sessionKey !== null) {
      dispatch(getListBookmarks(sessionKey))
    }
  }, [sessionKey, dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: (row, index) => {
          return index + 1
        },
      },
      {
        Header: " ",
        accessor: (values) => {
          const candidate = values
          return candidate
        },

        Cell: ({ cell }) => {
          const { value } = cell
          return (
            <div className="d-flex justify-content-center align-items-center">
              <SetFavorite
                bookmarkedCandidate={value}
                sessionKey={sessionKey}
                protectedId={value.protected_candidate_id}
              />
              <Link
                to={`/recruiter/candidate/${value.first_name}/${value.last_name}/${value.protected_candidate_id}/profile`}
                target="_blank"
              >
                <BsSearch
                  size={20}
                  color="#4f16b6"
                  title="StudyCard Bekijken"
                />
              </Link>
              <div
                className="cursor-pointer mx-2"
                onClick={() => deleteBookmark(sessionKey, value)}
              >
                <BsTrash
                  size={20}
                  className="delete-bookmark-icon"
                  title="Verwijderen"
                />
              </div>{" "}
            </div>
          )
        },
      },

      {
        Header: "Kandidaat",
        accessor: (values) => {
          return values
        },

        Cell: ({ cell }) => {
          const { value } = cell
          return (
            <div className="bookmark-item-name">
              <Link
                to={`/recruiter/candidate/${value.first_name}/${value.last_name}/${value.protected_candidate_id}/profile`}
                target="_blank"
              >
                {value.first_name} {value.last_name}
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Plaats",
        accessor: (values) => {
          return values.work_city_name
        },
      },
      {
        Header: "Toestand",
        accessor: (values) => {
          const candidate = values
          return candidate
        },

        Cell: ({ cell }) => {
          const { value } = cell
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`bookmark-item-status-button ${
                  value.user_job_search_state_id === 0
                    ? "unavailable"
                    : "available"
                }`}
              >
                {0 ? "Unavailable" : "Available"}
              </div>
            </div>
          )
        },
      },
    ],
    [sessionKey]
  )
  return (
    <div className="container  flex-grow-1 py-5">
      <h3 className="font-weight-bold py-2 mb-3">Kandidaten</h3>

      <div className="shadow p-4 bg-white">
        <div className="text-center ">
          <span>
            Zoeken:{" "}
            <input
              value={filterInput}
              onChange={(e) => setFilterInput(e.target.value)}
            />
          </span>

          <div className="col-lg-12 mt-3">
            <Table data={filteredBookmarks} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bookmarks
