import NavbarItem from "./NavbarItem"

export default function LoggedOut({ role }) {
  return (
    <>
      <NavbarItem path={`/${role}/login`} linkText="Login" eventKey="login" />
      <NavbarItem
        path={`/${role}/signup`}
        linkText="Registeer"
        eventKey="singup"
      />
    </>
  )
}
