import React from "react"
import { useTable, useSortBy, usePagination } from "react-table"
import { Row, Col, Button } from "react-bootstrap"

export default function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  )
  return (
    <div
      className="table-responsive"
      style={{ textAlign: "center", fontSize: "15px" }}
    >
      <table className="mb-0 table table-hover" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th className="align-middle bt-0" key={index}>
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "▲" : "▼") : ""}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length > 0 ? (
        <Row
          style={{ maxWidth: 1000, margin: "1rem auto", textAlign: "center" }}
        >
          <Col md={3}>
            <Button
              style={{ background: "#4f16b6", border: "none" }}
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={6} style={{ marginTop: 7 }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col md={3}>
            <Button
              style={{ background: "#4f16b6", border: "none" }}
              onClick={nextPage}
              disabled={!canNextPage}
            >
              {">"}
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  )
}
