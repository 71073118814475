import React from "react"
import { useDispatch } from "react-redux"
import { FaPlus } from "react-icons/fa"

import { bookmarkACandi } from "../../store/recruiter/action"

const AddBookmark = ({ candidate, sessionKey }) => {
  const dispatch = useDispatch()

  //Handle click on bookmark button
  const onClickAddBookmark = (candidate) => {
    dispatch(bookmarkACandi(sessionKey, candidate))
  }

  return (
    <div
      onClick={() => onClickAddBookmark(candidate)}
      className="cursor-pointer mx-2"
    >
      <FaPlus color="#4f16b6" title="Opslaan" size={20} />
    </div>
  )
}

export default AddBookmark
