import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import { Markup } from "react-render-markup"

import TitleRow from "../../../components/TitleRow/TitleRow"
import { selectRedirectUrl } from "../../../store/appState/selector"
import { redirectDone } from "../../../store/appState/action"

export default function Profile({ studyDetail }) {
  const redirectToUrl = useSelector(selectRedirectUrl)
  const dispatch = useDispatch()

  useEffect(() => {
    if (redirectToUrl) {
      dispatch(redirectDone())
    }
  }, [redirectToUrl, dispatch])
  return (
    <>
      {studyDetail && (
        <Container
          style={{
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <TitleRow title="Profiel" />
          <div className="card-contents text-justify py-3 ">
            <h5>Uitleg</h5>
            <Markup markup={studyDetail.profile_description} />
          </div>
        </Container>
      )}
    </>
  )
}
