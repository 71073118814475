import Navbar from "react-bootstrap/Navbar"
import { Nav, NavDropdown } from "react-bootstrap"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import "./index.css"

import LoggedOut from "./LoggedOut"
import LoggedIn from "./LoggedIn"
import NavbarItem from "./NavbarItem"
import RBAC from "../../utils/RBAC"

import { selectSessionKey } from "../../store/user/selector"
import {
  selectMyStudies,
  selectStudyDetails,
} from "../../store/student/selector"

export default function Navigation() {
  const sessionKey = useSelector(selectSessionKey)
  const myStudies = useSelector(selectMyStudies)
  const myStudyDetail = useSelector(selectStudyDetails)
  const pathName = useLocation().pathname

  const rolePerPath = () => {
    if (pathName.includes("recruiter")) {
      return "recruiter"
    } else if (pathName.includes("student")) {
      return "student"
    } else return "student"
  }

  const loginLogoutControls = sessionKey ? (
    <LoggedIn role={rolePerPath()} />
  ) : (
    <LoggedOut role={rolePerPath()} />
  )
  return (
    <Navbar
      bg="light"
      expand="xl"
      collapseOnSelect
      fixed="top"
      style={{
        minHeight: "4rem",
        padding: ".5rem 1rem",
        boxShadow: sessionKey ? "0 2px 4px -1px rgba(0,0,0,0.25)" : "none",
      }}
    >
      {!sessionKey && (
        <Navbar.Brand>
          <Link to="/">
            <Navbar.Brand>
              <strong>StudyCard </strong> | Skills Made Visible
            </Navbar.Brand>
          </Link>
        </Navbar.Brand>
      )}
      <RBAC allowedRoles={["recruiter"]}>
        <Link to="/recruiter/dashboard">
          <Navbar.Brand>
            <strong>StudyCard </strong> | Skills Made Visible
          </Navbar.Brand>
        </Link>
      </RBAC>
      <RBAC allowedRoles={["student"]}>
        <Link to="/student/studies">
          <Navbar.Brand>
            <strong>StudyCard </strong> | Skills Made Visible
          </Navbar.Brand>
        </Link>
      </RBAC>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="px-1" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <RBAC allowedRoles={["recruiter"]}>
            <NavbarItem
              path="/recruiter/bookmark-candidates"
              eventKey="Bookmarks"
              linkText="Kandidaten"
            />
            <NavbarItem
              path="/recruiter/job-cards"
              eventKey="JobCards"
              linkText="JobCards"
            />
            <NavbarItem
              path="/recruiter/dashboard"
              eventKey="Dashboard"
              linkText="Dashboard"
            />
          </RBAC>
          <RBAC allowedRoles={["student"]}>
            {sessionKey && (
              <NavDropdown
                title="Studies"
                id="collasible-nav-dropdown"
                style={{ marginRight: "1rem" }}
              >
                {myStudies?.map((study, index) => (
                  <NavDropdown.Item
                    key={index}
                    as={NavLink}
                    to={`/student/studies/${study.profile_id}/${study.education_system_id}`}
                    eventKey={`profile ${study.profile_id}`}
                  >
                    {study.education_name}
                  </NavDropdown.Item>
                ))}
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={NavLink}
                  eventKey={10}
                  to="/student/studies/add-study"
                >
                  Studie Toevoegen
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {sessionKey && myStudies && myStudies.length > 0 ? (
              <>
                {" "}
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/profile`}
                  linkText="Profiel"
                  eventKey="profile"
                />
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/coretasks`}
                  linkText="Kerntaken"
                  eventKey="coretasks"
                />
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/workprocesses`}
                  style={{ padding: ".5rem 1rem", textDecoration: "none" }}
                  linkText="Werkprocessen"
                  eventKey="workprocesses"
                />
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/competences`}
                  style={{ padding: ".5rem 1rem", textDecoration: "none" }}
                  linkText="Competenties"
                  eventKey="competences"
                />
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/skills`}
                  style={{ padding: ".5rem 1rem", textDecoration: "none" }}
                  linkText="Vaardigheden"
                  eventKey="skills"
                />
                <NavbarItem
                  path={`/student/studies/${myStudyDetail?.profile_id}/${myStudyDetail?.education_system_id}/scorecards`}
                  style={{ padding: ".5rem 1rem", textDecoration: "none" }}
                  linkText="Scorecards"
                  eventKey="scorecards"
                />
              </>
            ) : null}
          </RBAC>
          {loginLogoutControls}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
