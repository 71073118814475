export const SET_MESSAGE = "SET_MESSAGE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"

export const APP_LOADING = "APP_LOADING"
export const APP_DONE_LOADING = "APP_DONE_LOADING"

export const APP_LOADING_SMALL = "APP_LOADING_SMALL"
export const APP_DONE_LOADING_SMALL = "APP_DONE_LOADING_SMALL"

export const appLoading = () => ({ type: APP_LOADING })
export const appDoneLoading = () => ({ type: APP_DONE_LOADING })
export const appLoadingSmall = () => ({ type: APP_LOADING_SMALL })
export const appDoneLoadingSmall = () => ({ type: APP_DONE_LOADING_SMALL })
export const clearMessage = () => ({ type: CLEAR_MESSAGE })

export const setMessage = (variant, dismissable, text) => {
  return {
    type: SET_MESSAGE,
    payload: {
      variant,
      dismissable,
      text,
    },
  }
}

export const showMessageWithTimeout = (
  variant,
  dismissable,
  text,
  timeOutMilliSeconds
) => {
  return (dispatch) => {
    dispatch(setMessage(variant, dismissable, text))

    const timeout = timeOutMilliSeconds || 3000

    setTimeout(() => dispatch(clearMessage()), timeout)
  }
}

export const REDIRECT_SUCCESS = "REDIRECT_SUCCESS"
export const redirectSuccess = (url) => {
  return { type: REDIRECT_SUCCESS, payload: url }
}

export const REDIRECT_DONE = "REDIRECT_DONE"
export const redirectDone = () => {
  return { type: REDIRECT_DONE }
}
