import { useDispatch, useSelector } from "react-redux"
import { Nav, NavDropdown } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { FaUserCircle } from "react-icons/fa"
import { logOut } from "../../store/user/action"
import { selectPersonalDetails } from "../../store/user/selector"
import NavbarItem from "./NavbarItem"

export default function LoggedIn({ role }) {
  const dispatch = useDispatch()
  const personalDetails = useSelector(selectPersonalDetails)
  return (
    <>
      <Nav.Item style={{ marginRight: "1rem" }}>
        <NavDropdown
          title={<FaUserCircle />}
          id="collasible-nav-dropdown"
          alignRight
        >
          {personalDetails && (
            <>
              <NavDropdown.Item
                eventKey="general"
                as={NavLink}
                to={`/${role}/user-setting/general`}
              >
                {personalDetails.first_name} {personalDetails.last_name}
              </NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav.Item>
      <NavbarItem
        path={`/${role}/login`}
        linkText="Uitloggen"
        eventKey="logout"
        onClick={() => dispatch(logOut())}
      />
    </>
  )
}
