import {
  CREATE_JOB_CARD,
  SEARCH_CANDIDATES,
  CLEAR_RESULTS,
  GET_CANDIDATE_STUDIES,
  GET_CANDIDATE_STUDY_DETAIL,
  GET_LIST_BOOKMARKS,
  BOOKMARK_CANDIDATE_SUCCESS,
  SET_FAVORITE_SUCCESS,
  DELETE_BOOKMARK_CANDIDATE_SUCCESS,
  GET_LIST_JOBCARDS,
  GET_LIST_JOBCARD_VERSIONS,
  GET_LIST_JOBCARD_VERSION_SKILLS,
} from "./action"
import { LOG_OUT_SUCCESS, DELETE_ACCOUNT } from "../user/action"

const initialState = {
  jobCards: null,
  candidates: null,
  bookmarkCandidates: [],
  jobCardId: null,
  jobCardVersions: null,
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_JOBCARDS:
      return { ...state, jobCards: action.payload }
    case GET_LIST_JOBCARD_VERSIONS:
      return { ...state, jobCardVersions: action.payload }
    case GET_LIST_JOBCARD_VERSION_SKILLS:
      return {
        ...state,
        jobCardVersionSkills: action.payload,
      }
    case CREATE_JOB_CARD:
      return {
        ...state,
        jobCards: [...state.jobCards, action.payload],
        jobCardId: action.payload,
      }
    case SEARCH_CANDIDATES:
      return { ...state, candidates: action.payload }
    case CLEAR_RESULTS:
      return { ...state, candidates: action.payload }
    case GET_CANDIDATE_STUDIES:
      return { ...state, candidateStudies: action.payload }
    case GET_CANDIDATE_STUDY_DETAIL:
      return { ...state, candidateStudyDetail: action.payload }
    case GET_LIST_BOOKMARKS:
      return { ...state, bookmarkCandidates: action.payload }
    case BOOKMARK_CANDIDATE_SUCCESS:
      const newCandidates = state.candidates.map((can) =>
        can.protected_anonymous_id ===
        action.payload.updatedSearchedCandidate.protected_anonymous_id
          ? { ...action.payload.updatedSearchedCandidate }
          : can
      )

      return {
        ...state,
        candidates: newCandidates,
        bookmarkCandidates: [
          ...state.bookmarkCandidates,
          action.payload.bookmarkedCandidate,
        ],
      }
    case DELETE_BOOKMARK_CANDIDATE_SUCCESS:
      return {
        ...state,
        bookmarkCandidates: [
          ...state.bookmarkCandidates.filter(
            (bookmark) =>
              bookmark.protected_candidate_id !==
              action.payload.protected_candidate_id
          ),
        ],
      }
    case SET_FAVORITE_SUCCESS:
      const newBookmarkCandidates = state.bookmarkCandidates.map((bookmark) =>
        bookmark.protected_candidate_id ===
        action.payload.bookmarkedCandidate.protected_candidate_id
          ? { ...action.payload.bookmarkedCandidate }
          : bookmark
      )
      const newSearchedCandidates = state.candidates?.map((can) =>
        can.protected_anonymous_id ===
        action.payload.searchedCandidate.protected_anonymous_id
          ? { ...action.payload.searchedCandidate }
          : can
      )
      return {
        ...state,
        candidates: newSearchedCandidates,
        bookmarkCandidates: newBookmarkCandidates,
      }

    case LOG_OUT_SUCCESS:
      return { ...initialState }
    case DELETE_ACCOUNT:
      return { ...initialState }
    default:
      return state
  }
}
