import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

const JobCardMini = ({ jobCard, role }) => {
  return (
    <Link
      to={`/${role}/job-card/${jobCard.job_card_id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <div className="job-card-mini border p-2 my-3 rounded  cursor-pointer">
        <h5 className="job-card-mini__title font-weight-bold">
          {jobCard.name}
        </h5>
        <h6 className="job-card-mini__sub-title">
          {moment(jobCard.created_at).format("MM/DD/YYYY")}
        </h6>
      </div>
    </Link>
  )
}

export default JobCardMini
