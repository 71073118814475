import React from "react"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"

import { selectApi } from "../store/user/selector"
export default function RouteWrapper({ Component, ...props }) {
  // need to rename again in future because api != role
  const userRole = useSelector(selectApi)
  const allowRoles = props.roles
  function checkAccess(userRole, allowRoles) {
    return allowRoles.includes(userRole)
  }
  const access = checkAccess(userRole, allowRoles)
  if (userRole && !access) {
    return <Redirect to="/error" />
  }
  return (
    <div>
      {" "}
      <Route
        {...props}
        render={({ ...routeProps }) => <Component {...routeProps} />}
      />
    </div>
  )
}
