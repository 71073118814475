import React, { useEffect, useMemo } from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { selectSessionKey, selectExpVariant } from "./store/user/selector"
import {
  getPersonalDetails,
  getPersionalDetailsWithStoredSessionKey,
  getCurrentRole,
  getExperimentVariantSuccess,
} from "./store/user/action"
import { selectAppLoading } from "./store/appState/selector"
import { apiPost } from "./utils/apiPostWrapper"

import Navigation from "./components/Navigation"
import MessageBox from "./components/MessageBox"
import Loading from "./components/Loading/Loading"
import Home from "./pages/Common/Home/Home"
import StudentWrapper from "./pages/Wrappers/StudentWrapper"
import RecruiterWrapper from "./pages/Wrappers/RecruiterWrapper"
import Footer from "./components/Footer/Footer"
import ForgotPassword from "./pages/Common/ResetPassword/ForgotPassword"

import "./App.css"
import ErrorPage from "./pages/Common/ErrorPage/ErrorPage"

function App() {
  const sessionKey = useSelector(selectSessionKey)
  const isLoading = useSelector(selectAppLoading)
  const dispatch = useDispatch()
  const history = useHistory()
  const id = useMemo(uuidv4, [])
  const tempExpVariant = useSelector(selectExpVariant)

  useEffect(() => {
    dispatch(getPersionalDetailsWithStoredSessionKey())

    if (sessionKey) {
      dispatch(getCurrentRole(sessionKey))
      dispatch(getPersonalDetails(sessionKey))
    }
  }, [sessionKey, dispatch, history])

  if (!sessionStorage.tabId) {
    sessionStorage.setItem("tabId", id)
  }

  //experiments from storage or redux store
  const experiment =
    (tempExpVariant && tempExpVariant) ||
    JSON.parse(sessionStorage.getItem("experimentVariant"))

  // request experiments
  useEffect(() => {
    async function getExperiment() {
      if (!experiment) {
        try {
          const experimentConfig = await apiPost(
            "/api/common/analytics/experiment_variant",
            {
              experiments: {
                "landing-page": ["always-redirect-registration"],
                "student-registration-work-and-recruiter": null,
              },
            }
          )
          dispatch(
            getExperimentVariantSuccess(experimentConfig.data.experiments)
          )
        } catch (error) {
          if (error.response) {
            console.log(error.response.data)
          } else {
            console.log(error.message)
          }
        }
      }
    }
    getExperiment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div id="app" className="App">
      <Navigation />
      <MessageBox />
      {isLoading ? <Loading size="large" /> : null}
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <div className="page">
              <Home api="student" />
            </div>
          )}
        />
        <Route path="/student" component={StudentWrapper} />
        <Route path="/recruiter" component={RecruiterWrapper} />
        <Route
          path={`/forgot-password`}
          render={({ ...props }) => (
            <div className="page">
              <ForgotPassword {...props} />
            </div>
          )}
        />
        <Route
          path="/error"
          render={({ ...props }) => <ErrorPage {...props} />}
        />
      </Switch>
      <Footer />
    </div>
  )
}

export default App
