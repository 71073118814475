import { apiPost } from "../../utils/apiPostWrapper"
import {
  showMessageWithTimeout,
  appLoading,
  appDoneLoading,
  appLoadingSmall,
  appDoneLoadingSmall,
  redirectSuccess,
} from "../appState/action"

// SET/REMOVE FAVORITE A CANDIDATE
export const SET_FAVORITE_SUCCESS = "SET_FAVORITE_SUCCESS"

const setFavoriteSuccess = (data) => {
  return {
    type: SET_FAVORITE_SUCCESS,
    payload: data,
  }
}

export const setFavorite = (sessionKey, protectedId, isFavorite, candidate) => {
  return async (dispatch, getState) => {
    // dispatch(appLoading())
    try {
      const response = await apiPost(`/api/recruiter/candidate/set_favorite`, {
        session_key: sessionKey,
        protected_anonymous_id: protectedId,
        is_favorite: isFavorite,
      })

      if (response.data.candidate !== null) {
        dispatch(
          setFavoriteSuccess({
            searchedCandidate: { ...candidate, is_favorite: isFavorite },
            bookmarkedCandidate: response.data.candidate,
          })
        )
      } else {
        dispatch(
          showMessageWithTimeout(
            "danger",
            true,
            "De kandidaat heeft de toestemming voor het delen van informatie ingetrokken.",
            4000
          )
        )
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        console.log(error.message)
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
    }
  }
}

// BOOKMARK A CANDIDATE SUCCESSFULLY
export const BOOKMARK_CANDIDATE_SUCCESS = "BOOKMARK_CANDIDATE_SUCCESS"

const bookmarkACandiSuccess = (candidate) => {
  return {
    type: BOOKMARK_CANDIDATE_SUCCESS,
    payload: candidate,
  }
}

export const bookmarkACandi = (sessionKey, searchedCandidate) => {
  return async (dispatch, getState) => {
    try {
      const response = await apiPost(`/api/recruiter/candidate/add_bookmark`, {
        session_key: sessionKey,
        protected_anonymous_id: searchedCandidate.protected_anonymous_id,
        is_favorite: false,
      })

      if (response.data.candidate !== null) {
        dispatch(
          bookmarkACandiSuccess({
            updatedSearchedCandidate: {
              ...searchedCandidate,
              is_bookmarked: true,
            },
            bookmarkedCandidate: response.data.candidate,
          })
        )
        dispatch(
          showMessageWithTimeout(
            "success",
            true,
            "Met succes opgeslagen.",
            4000
          )
        )
      } else {
        dispatch(
          showMessageWithTimeout(
            "danger",
            true,
            "De kandidaat heeft de toestemming voor het delen van informatie ingetrokken.",
            4000
          )
        )
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
    }
  }
}

// BOOKMARK A CANDIDATE SUCCESSFULLY
export const DELETE_BOOKMARK_CANDIDATE_SUCCESS =
  "DELETE_BOOKMARK_CANDIDATE_SUCCESS"

const deleteBookmarkACandiSuccess = (candidate) => {
  return {
    type: DELETE_BOOKMARK_CANDIDATE_SUCCESS,
    payload: candidate,
  }
}

export const deleteBookmarkACandi = (sessionKey, candidate) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      await apiPost(`/api/recruiter/candidate/delete_bookmark`, {
        session_key: sessionKey,
        protected_anonymous_id: candidate.protected_candidate_id,
      })
      dispatch(deleteBookmarkACandiSuccess(candidate))
      dispatch(
        showMessageWithTimeout("success", true, "Removed bookmark", 4000)
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// GET A LIST OF BOOKMARK CANDIDATES
export const GET_LIST_BOOKMARKS = "GET_LIST_BOOKMARKS"

const getListBookmarksSuccess = (listBookmarks) => {
  return {
    type: GET_LIST_BOOKMARKS,
    payload: listBookmarks,
  }
}

export const getListBookmarks = (sessionKey) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/recruiter/candidate/list_bookmarks`,
        {
          session_key: sessionKey,
        }
      )
      dispatch(getListBookmarksSuccess(response.data.candidates))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
    }
  }
}

// Create a job card
export const CREATE_JOB_CARD = "CREATE_JOB_CARD"

const createJobCardSuccess = (jobCardId) => {
  return {
    type: CREATE_JOB_CARD,
    payload: jobCardId,
  }
}

export const createJobCard = (
  sessionKey,
  jobTitle,
  location,
  description,
  profile,
  skillsArray
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost("/api/recruiter/jobcard/add_jobcard", {
        session_key: sessionKey,
        name: jobTitle,
      })
      dispatch(
        createJobCardVersion(
          sessionKey,
          response.data.job_card_id,
          location,
          description,
          profile,
          skillsArray
        )
      )
      dispatch(createJobCardSuccess(response.data.job_card_id))
      dispatch(
        redirectSuccess(`/recruiter/job-card/${response.data.job_card_id}`)
      )
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "JobCard is succesvol aangemaakt",
          4000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// Create a job card version
export const CREATE_JOB_CARD_VERSION = "CREATE_JOB_CARD_VERSION"

const createJobCardVersionSuccess = (jobCardVersionId) => {
  return {
    type: CREATE_JOB_CARD_VERSION,
    payload: jobCardVersionId,
  }
}

export const createJobCardVersion = (
  sessionKey,
  jobCardId,
  location,
  description,
  profile,
  skillsArray
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        "/api/recruiter/jobcard/add_jobcard_version",
        {
          session_key: sessionKey,
          job_card_id: jobCardId,
          work_city_id: location,
          description: description,
          skill_ids: skillsArray,
          job_title_id: profile,
        }
      )

      dispatch(createJobCardVersionSuccess(response.data.job_card_version_id))
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "Jobcard is succesvol bijgewerkt.",
          4000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// Get  a list of jobCards
export const GET_LIST_JOBCARDS = "GET_LIST_JOBCARDS"

const getListJobCardsSuccess = (jobCards) => {
  return {
    type: GET_LIST_JOBCARDS,
    payload: jobCards,
  }
}

export const getListJobCards = (sessionKey) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost("/api/recruiter/jobcard/list_jobcards", {
        session_key: sessionKey,
      })

      dispatch(getListJobCardsSuccess(response.data.jobcards))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// Get a list of jobCard Versions
export const GET_LIST_JOBCARD_VERSIONS = "GET_LIST_JOBCARD_VERSIONS"

const getListJobCardVersionsSuccess = (jobCardVersions) => {
  return {
    type: GET_LIST_JOBCARD_VERSIONS,
    payload: jobCardVersions,
  }
}

export const getListJobCardVersions = (sessionKey, jobCardId) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        "/api/recruiter/jobcard/list_jobcard_versions",
        {
          session_key: sessionKey,
          job_card_id: jobCardId,
        }
      )

      dispatch(getListJobCardVersionsSuccess(response.data.jobcards))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// Get a list of jobCard Version Skills
export const GET_LIST_JOBCARD_VERSION_SKILLS = "GET_LIST_JOBCARD_VERSION_SKILLS"

const getListJobCardVersionSkillsSuccess = (jobCardVersions) => {
  return {
    type: GET_LIST_JOBCARD_VERSION_SKILLS,
    payload: jobCardVersions,
  }
}

export const getListJobCardVersionSkills = (sessionKey, jobCardVersionId) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        "/api/recruiter/jobcard/list_jobcard_version_skills",
        {
          session_key: sessionKey,
          job_card_version_id: jobCardVersionId,
        }
      )

      dispatch(getListJobCardVersionSkillsSuccess(response.data.skills))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// SEARCH CANDIDATE ACTION
export const SEARCH_CANDIDATES = "SEARCH_CANDIDATES"

const searchCandidatesSuccess = (candidates) => {
  return {
    type: SEARCH_CANDIDATES,
    payload: candidates,
  }
}
export const searchCandidates = (
  session_key,
  search_terms,
  job_title_id,
  job_city_id,
  job_skill_ids
) => {
  return async (dispatch, getState) => {
    dispatch(appLoadingSmall())
    try {
      const response = await apiPost(
        `/api/recruiter/search/candidates`,

        {
          session_key,
          search_terms,
          job_title_id: job_title_id,
          job_city_id: job_city_id ?? null,
          job_skill_ids: job_skill_ids,
        }
      )
      dispatch(searchCandidatesSuccess(response.data.candidates))
      dispatch(appDoneLoadingSmall())
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoadingSmall())
    }
  }
}

// CLEAR SEARCH RESULT
export const CLEAR_RESULTS = "CLEAR_RESULTS"

const clearCandidatesSuccess = (candidates) => {
  return {
    type: CLEAR_RESULTS,
    payload: candidates,
  }
}
export const clearCandidates = () => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    dispatch(clearCandidatesSuccess([]))

    dispatch(appDoneLoading())
  }
}

//GET CANDIDATE STUDIES for RECRUITER
export const GET_CANDIDATE_STUDIES = "GET_CANDIDATE_STUDIES"

const getCandidateStudiesSuccess = (candidateStudies) => {
  return {
    type: GET_CANDIDATE_STUDIES,
    payload: candidateStudies,
  }
}

export const getCandidateStudies = (sessionKey, candidateId) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      const response = await apiPost(`/api/recruiter/candidate/list_studies`, {
        session_key: sessionKey,
        protected_anonymous_id: candidateId,
      })

      dispatch(getCandidateStudiesSuccess(response.data.studies))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// GET STUDY DETAIL  OF CANDIDATE FOR RECRUITER
export const GET_CANDIDATE_STUDY_DETAIL = "GET_CANDIDATE_STUDY_DETAIL"

const getCandidateStudyDetailSuccess = (study) => {
  return {
    type: GET_CANDIDATE_STUDY_DETAIL,
    payload: study,
  }
}

export const getCandidateStudyDetail = (
  sessionKey,
  candidateId,
  profileId,
  eduSystem
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/recruiter/candidate/study_details_mbo_${eduSystem}`,

        {
          session_key: sessionKey,
          protected_anonymous_id: candidateId,
          profile_id: profileId,
        }
      )

      dispatch(getCandidateStudyDetailSuccess(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}
