export const selectJobCards = (state) => state.recruiter.jobCards
export const selectJobCardVersions = (state) => state.recruiter.jobCardVersions
export const selectJobCardVersionSkills = (state) =>
  state.recruiter.jobCardVersionSkills

export const selectJobCardId = (state) => state.recruiter.jobCardId
export const selectCandidateResults = (state) => state.recruiter.candidates
export const selectCandidateStudies = (state) =>
  state.recruiter.candidateStudies
export const selectCandidateStudyDetail = (state) =>
  state.recruiter.candidateStudyDetail
export const selectBookmarkCandidates = (state) =>
  state.recruiter.bookmarkCandidates
