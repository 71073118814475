import React, { useState, useEffect } from "react"
import { apiPost } from "../../../../utils/apiPostWrapper"

const ChangePassword = ({ sessionKey, role }) => {
  const [inviteCode, setInviteCode] = useState("")
  const [visible, setVisible] = useState(false)

  const getInviteCode = async () => {
    try {
      const response = await apiPost(`/api/common/account/add_invitation`, {
        session_key: sessionKey,
      })
      setInviteCode(response.data.invitation_key)
    } catch (error) {
      console.log("error", error.response.data)
    }
  }
  //copy to clipboard function
  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy)
    } else {
      // text area method
      let textArea = document.createElement("textarea")
      textArea.value = textToCopy
      // make the textarea out of viewport
      textArea.style.position = "fixed"
      textArea.style.left = "-999999px"
      textArea.style.top = "-999999px"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej()
        textArea.remove()
      })
    }
  }
  const invitationLink = `https://test.scorecard.school/student/signup?invitation=${inviteCode}`
  const handleCopy = async () => {
    copyToClipboard(invitationLink)
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
    }, 2000)
  }

  useEffect(() => {
    sessionKey && getInviteCode()
    // eslint-disable-next-line
  }, [sessionKey])

  return (
    <div>
      <h4 className="mb-4">Nodig Een Vriend Uit</h4>

      <p>
        Nodig je vrienden uit om zich aan te melden voor StudyCard met de
        volgende link
      </p>

      <input
        type="text"
        readOnly
        value={invitationLink}
        className="invite-link w-100"
      />
      <div className="d-flex flex-row align-items-center">
        <button className="button_outline my-3" onClick={handleCopy}>
          Kopieer Link
        </button>
        {visible && (
          <div className="px-2">
            <i>Gekopieerd!</i>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChangePassword
