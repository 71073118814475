import React, { useEffect, useState, useRef, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, Link } from "react-router-dom"
import { BsSearch } from "react-icons/bs"
// import moment from "moment"
import { useForm, Controller } from "react-hook-form"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import TextareaAutosize from "react-textarea-autosize"
import { BsTrash } from "react-icons/bs"

import { apiPost } from "../../../utils/apiPostWrapper"
import AddBookmark from "../../../components/AddBookMark/AddBookmark"
import SetFavorite from "../../../components/SetFavorite/SetFavorite"
import Table from "../../../components/Table/Table"

import {
  selectJobCardVersions,
  selectCandidateResults,
} from "../../../store/recruiter/selector"
import {
  getListJobCardVersions,
  searchCandidates,
  createJobCardVersion,
} from "../../../store/recruiter/action"
import { selectSessionKey } from "../../../store/user/selector"
import {
  showMessageWithTimeout,
  redirectDone,
} from "../../../store/appState/action"
import {
  selectRedirectUrl,
  selectAppLoadingSmall,
} from "../../../store/appState/selector"
import Loading from "../../../components/Loading/Loading"

const JobCardDetail = ({ role }) => {
  const ref = useRef()
  const redirectToUrl = useSelector(selectRedirectUrl)
  const isLoadingSmall = useSelector(selectAppLoadingSmall)
  const jobCardId = parseInt(useParams().id)
  const jobCardVersions = useSelector(selectJobCardVersions)
  const candidateResults = useSelector(selectCandidateResults)
  const chosenJobCardVer =
    jobCardVersions && jobCardVersions[jobCardVersions.length - 1]
  const sessionKey = useSelector(selectSessionKey)
  const dispatch = useDispatch()
  const [cityOptions, setCityOptions] = useState([])
  const [isCityLoading, setIsCityLoading] = useState(false)
  const [profileOptions, setProfileOptions] = useState([])
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const [skilloptions, setSkillOptions] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [isSkillsLoading, setIsSkillsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const originalData = useMemo(() => {
    return {
      jobTitle: chosenJobCardVer?.job_card_name,
      description: chosenJobCardVer?.description,
      location: [
        {
          id: chosenJobCardVer?.work_city_id,
          name: chosenJobCardVer?.work_city_name,
        },
      ],
      profile: [
        {
          id: chosenJobCardVer?.job_title_id,
          name: chosenJobCardVer?.job_title_name,
        },
      ],
    }
  }, [chosenJobCardVer])

  // SEARCH CANDIDATES
  const handleSearch = async (skills) => {
    dispatch(
      searchCandidates(
        sessionKey,
        "bug",
        profile[0].id,
        location[0].id,
        skills.map((skill) => skill.id)
      )
    )
  }

  //Remove redirect url after render page
  useEffect(() => {
    if (redirectToUrl) {
      dispatch(redirectDone())
    }
  }, [redirectToUrl, dispatch])

  //Fetch Jobcard Versions
  useEffect(() => {
    if (sessionKey) {
      dispatch(getListJobCardVersions(sessionKey, jobCardId))
    }
  }, [dispatch, sessionKey, jobCardId])

  //Then when have the chosen Version, fetch skills of that versions, and set it to state
  useEffect(() => {
    if (sessionKey && chosenJobCardVer) {
      async function getSkills(handleSearchCallBack) {
        try {
          const response = await apiPost(
            "/api/recruiter/jobcard/list_jobcard_version_skills",
            {
              session_key: sessionKey,
              job_card_version_id: chosenJobCardVer.job_card_version_id,
            }
          )
          setSelectedSkills(response.data.skills)
          handleSearchCallBack(response.data.skills)
        } catch (error) {
          if (error.response) {
            dispatch(
              showMessageWithTimeout("danger", true, error.response.data, 5000)
            )
          }
          dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
        }
      }
      getSkills(handleSearch)
    }
  }, [dispatch, sessionKey, chosenJobCardVer])

  //useForm() from reac-hook-form for updating the fields
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: originalData,
    shouldUnregister: false,
    mode: "onChange",
  })

  // watch for changes of fields
  const [description, location, profile] = watch([
    "description",
    "location",
    "profile",
  ])

  //Persist input in first render
  useEffect(() => {
    reset({
      jobTitle: chosenJobCardVer?.job_card_name,
      description: chosenJobCardVer?.description,
      location: [
        {
          id: chosenJobCardVer?.work_city_id,
          name: chosenJobCardVer?.work_city_name,
        },
      ],
      profile: [
        {
          id: chosenJobCardVer?.job_title_id,
          name: chosenJobCardVer?.job_title_name,
        },
      ],
    })
  }, [chosenJobCardVer, reset])

  // GET CITIES
  const loadCityOptions = async (searchTerm) => {
    try {
      setIsCityLoading(true)
      const citiesResponse = await apiPost(`/api/common/search/city`, {
        search_term: searchTerm,
      })
      setCityOptions(citiesResponse.data.cities)
      setIsCityLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsCityLoading(false)
    }
  }

  // GET PROFILE
  const loadProfileOptions = async (searchTerm) => {
    try {
      setIsProfileLoading(true)
      const profilesResponse = await apiPost(`/api/common/search/job_title`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setProfileOptions(profilesResponse.data.job_titles)
      setIsProfileLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsProfileLoading(false)
    }
  }

  //load skills options
  const loadSkillOptions = async (searchTerm) => {
    try {
      setIsSkillsLoading(true)
      const skillResponse = await apiPost(`/api/common/search/skill`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setSkillOptions(skillResponse.data.skills)
      setIsSkillsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsSkillsLoading(false)
    }
  }

  // handle profile change
  const handleProfileChange = async (selectedProfile) => {
    try {
      const suggested = await apiPost(
        `/api/recruiter/search/relevant_skills_for_job`,
        {
          session_key: sessionKey,
          job_title_id: selectedProfile.id,
        }
      )
      setSelectedSkills(suggested.data.skills.slice(0, 5))
    } catch (error) {
      console.log("error", error)
    }
  }

  //On selecting a new skill, add it to existing selected skills state
  const onSelectSkill = (selected) => {
    setSelectedSkills([...selectedSkills, selected[0]])
    setIsOpen(false)
    if (selected.length) {
      ref.current.clear()
    }
  }

  const removeSkill = (skillId) => {
    setSelectedSkills(selectedSkills.filter((skill) => skill.id !== skillId))
  }

  //Update Jobcard function
  const handleUpdateJobCard = () => {
    dispatch(
      createJobCardVersion(
        sessionKey,
        jobCardId,
        location[0].id,
        description,
        profile[0].id,
        selectedSkills.map((skill) => skill.id)
      )
    )
    handleSearch(selectedSkills)
  }

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: (row, index) => {
          return index + 1
        },
      },
      {
        Header: " ",
        accessor: (row) => {
          return row
        },
        Cell: ({ value }) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              {!value.is_bookmarked && (
                <AddBookmark candidate={value} sessionKey={sessionKey} />
              )}

              {value.is_bookmarked && (
                <SetFavorite
                  bookmarkedCandidate={value}
                  sessionKey={sessionKey}
                  protectedId={value.protected_anonymous_id}
                />
              )}

              <Link
                to={`/recruiter/candidate/${value.first_name}/${value.last_name}/${value.protected_anonymous_id}/profile`}
                target="_blank"
                className="mx-2"
              >
                <BsSearch
                  size={20}
                  color="#4f16b6"
                  title="StudyCard Bekijken"
                />
              </Link>
            </div>
          )
        },
      },

      {
        Header: "Naam",
        accessor: (values) => {
          const first_name = values.first_name
          const last_name = values.last_name
          return first_name + " " + last_name
        },
      },
      {
        Header: "Afstand",
        accessor: (values) => {
          const distance = Math.round(values.distance_km)
          return distance
        },
        Cell: ({ value }) => {
          return <>{value} km </>
        },
      },
      {
        Header: "Vaardigheden",
        accessor: (values) => {
          const matchedSkillsArray = values.skill_ids.filter((s) =>
            selectedSkills.map((skill) => skill.id).includes(s)
          )
          return matchedSkillsArray.length
        },
        Cell: ({ value }) => {
          return (
            <>
              {value}/{selectedSkills?.length}
            </>
          )
        },
      },
    ],
    [sessionKey, selectedSkills]
  )

  return (
    <div className="job-card-board container light-style flex-grow-1 pt-5">
      <h4 className="font-weight-bold py-2 mb-3">JobCard</h4>
      <div className="row bg-white shadow rounded-lg p-3 ">
        {chosenJobCardVer && (
          <>
            <div className="p-2 col-lg-6">
              <form onSubmit={handleSubmit(handleUpdateJobCard)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="font-weight-bold">JobCard Title</label>
                      <input
                        className="form-control"
                        name="jobTitle"
                        type="text"
                        id="jobTitle"
                        defaultValue={originalData.jobTitle}
                        readOnly
                      />
                      {errors.jobTitle?.type === "required" && (
                        <p className="error__message">Deze invoer is vereist</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="font-weight-bold">Plaats</label>
                      <Controller
                        control={control}
                        name="location"
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <AsyncTypeahead
                            {...field}
                            filterBy={() => true}
                            labelKey={(option) => `${option.name}`}
                            isLoading={isCityLoading}
                            minLength={2}
                            onSearch={loadCityOptions}
                            // defaultInputValue={originalData.location}
                            defaultSelected={originalData.location}
                            options={cityOptions}
                            bsSize="sm"
                            id="job-city"
                            placeholder="Kies een stad..."
                          />
                        )}
                      />
                      {errors.location?.type === "required" && (
                        <p className="error__message">Deze invoer is vereist</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="font-weight-bold">
                        Functieomschrijving
                      </label>
                      <TextareaAutosize
                        className="form-control"
                        defaultValue={originalData.description}
                        minRows={8}
                        {...register("description", {
                          required: true,
                        })}
                      />

                      {errors.description?.type === "required" && (
                        <p className="error__message">Deze invoer is vereist</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="font-weight-bold">
                        Afgestudeerd Profiel
                      </label>
                      <Controller
                        control={control}
                        name="profile"
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <AsyncTypeahead
                            {...field}
                            defaultSelected={originalData.profile}
                            filterBy={() => true}
                            labelKey={(option) => `${option.name}`}
                            id="graduate-profile"
                            isLoading={isProfileLoading}
                            minLength={3}
                            onSearch={loadProfileOptions}
                            options={profileOptions}
                            onChange={(selected) => {
                              field.onChange(selected)
                              handleProfileChange(selected[0])
                            }}
                            bsSize="sm"
                            placeholder="Kies een profiel..."
                          />
                        )}
                      />
                      {errors.profile?.type === "required" && (
                        <p className="error__message">Deze invoer is vereist</p>
                      )}
                    </div>
                  </div>
                </div>
                {selectedSkills && selectedSkills.length > 0 && (
                  <div className="">
                    <div className="form-group ">
                      <label className="font-weight-bold"></label>

                      <div
                        className="table-responsive"
                        style={{ overflowX: "inherit" }}
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="w-10"></th>

                              <th className="w-80">Vaardigheden</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedSkills.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td className="">
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => removeSkill(item.id)}
                                    >
                                      {" "}
                                      <BsTrash size={20} title="Rerwijderen" />
                                    </div>{" "}
                                  </td>
                                  <td className="">
                                    <div>{item.name}</div>{" "}
                                  </td>
                                </tr>
                              )
                            })}
                            <tr>
                              <td></td>
                              <td>
                                <AsyncTypeahead
                                  ref={ref}
                                  filterBy={() => true}
                                  labelKey={(option) => `${option.name}`}
                                  id="skill-dropdown"
                                  isLoading={isSkillsLoading}
                                  minLength={3}
                                  onSearch={loadSkillOptions}
                                  onChange={onSelectSkill}
                                  options={skilloptions}
                                  onBlur={() => setIsOpen(false)}
                                  onFocus={() => setIsOpen(true)}
                                  open={isOpen}
                                  placeholder="Kies een vaardigheid..."
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-end ">
                  <button className="button_outline" type="submit">
                    JobCard Bijwerken
                  </button>
                </div>
              </form>
            </div>
            <div className="matched-candidates col-lg-6 py-2 ">
              <h5 className="font-weight-bold ">Overeenkomende Kandidaten</h5>
              {isLoadingSmall ? <Loading size="small" /> : null}
              {candidateResults?.length > 0 && (
                <div className="table-wrapper py-2">
                  <p>
                    Er zijn {candidateResults?.length} kandidaten die
                    overeenkomt met deze JobCar.
                  </p>

                  <Table data={candidateResults} columns={columns} />
                </div>
              )}
              {candidateResults?.length === 0 && (
                <div className="py-2">
                  <p>Er is 0 kandidaat die overeenkomt met deze JobCard.</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default JobCardDetail
